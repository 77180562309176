import './App.css';
import logo from './images/RootAccessRecords_logo_v3_transparent_white.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <footer className="Footer">
        At the root of this machine lies a collection of complex algorithms, each processing a subset of intricately networked sensory inputs. In time, you will be given Root Access to the interface into these processes, accomodating full neurospatial exploration and uplink capabilities.
        <br/><br/>
        Continue to monitor this access point for system updates...
      </footer>
    </div>
  );
}

export default App;
